/* eslint-disable react/style-prop-object */
import { useState, useEffect } from "react";
import { ShoppingCartOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import Categories from './Categories';
import { Drawer, message, Badge, Divider, Row, Col, Empty, Spin, Card, Space } from 'antd';

import request from './request';
import SingleView from "./SingleView";


const { Meta } = Card;

const Products =  props => {
    const { table, makeOrder, prefix, locale } = props;
    const intl = useIntl();
    const rlocale = locale==="ar"?"en":"ar";

    const [loading, setLoading] = useState(true);
    const [product, setProducts] = useState([]);
    const [drawer, setDrawer] = useState({ visible: false, row: null });

    useEffect(() => {
        request.get('/menu').then(response => {
            setLoading(false);
            setProducts(response.data);
        }).catch(e => {
            setLoading(false);
        });
    }, []);

    const LinkControl = () => {
        if(drawer.visible) onClose();
    }

    const setLink = row => {
        if(window.history){
            const extra=row?'#'+row.name_en:'';
            window.history.pushState(null, document.title, window.location.origin+window.location.search+extra);
        }
    }

    const showDrawer = row => {
        setDrawer({ visible: true, row });
        setLink(row);
    };
    const onClose = () => {
        setLink(null);
        setDrawer({ visible: false, row: null });
    };

    useEffect(() => {
        window.addEventListener('popstate', LinkControl);

        return () => {
            window.removeEventListener('popstate', LinkControl)
        }
    }, [drawer.visible]);

    if(loading) return <div style={{ width: '100%', textAlign: 'center' }}><Spin /></div>;

    if(product.length===0) return <Empty />

    const ret=[];
    let temp=[];
    const PER_ROW=4;
    //let counter=0;
    let categ=null;
    for(let i=0; i<product.length; i++){
        const item=product[i];
        if(categ===null){
            categ=item.category_name_ar;
            ret.push(<Divider id={item.category_name_en} key={Math.random()}>{item[`category_name_${locale}`] || item[`category_name_${rlocale}`]}</Divider>);
        }

        if(categ!==item.category_name_ar){
            categ=item.category_name_ar;
            ret.push(<Row style={{ marginBottom: '15px' }} gutter={[16, 16]} key={Math.random()}>{temp}</Row>);
            //counter=1;
            temp=[];
            ret.push(<Divider id={item.category_name_en} key={Math.random()}>{item[`category_name_${locale}`] || item[`category_name_${rlocale}`]}</Divider>)
        }

        temp.push(<Col key={item.id} xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <Badge.Ribbon className='huemix-robin' color="purple" text={<FormattedNumber value={item.price} style="currency" currency="SAR" />}><Card
                cover={
                <img
                    onClick={showDrawer.bind(null, item)}
                    alt={item.name_ar}
                    src={prefix+item.images[0]}
                />
                }
                actions={table?[<Space onClick={() => {
                    makeOrder({...item, extras:[] });
                    message.success(intl.formatMessage({
                        id: 'added-to-cart',
                        defaultMessage: 'تم إضـافة {name} للسلة بنجاح.'
                    }, {name: item[`name_${locale}`] || item[`name_${rlocale}`]}));
                }}><ShoppingCartOutlined /> <FormattedMessage id="add-to-cart" defaultMessage="إضـافة للسـلة" /></Space>]:[]}
            >
                <Meta
                title={item[`name_${locale}`] || item[`name_${rlocale}`]}
                description={item[`description_${locale}`] || item[`description_${rlocale}`]}
                />
                
            </Card></Badge.Ribbon>
        </Col>);
        /*counter++;
        if(counter===PER_ROW){
            ret.push(<Row style={{ marginBottom: '15px' }} gutter={[16, 16]} key={Math.random()}>{temp}</Row>);
            counter=1;
            temp=[];
        }*/
    }

    if(temp.length>0){
        ret.push(<Row style={{ marginBottom: '15px' }} gutter={[16, 16]} key={Math.random()}>{temp}</Row>);
    }

    return (
        <Row>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <Categories locale={locale} />
            </Col>
            <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                <Drawer 
                    title={false}
                    closable
                    destroyOnClose
                    placement="right"
                    width={isMobile ? '100%' : '50%'}
                    onClose={onClose}
                    visible={drawer.visible}
                >
                    <SingleView locale={locale} rlocale={rlocale} intl={intl} item={drawer} prefix={prefix} table={table} makeOrder={makeOrder} />
                </Drawer>
                {ret}
            </Col>
        </Row>
    )
}

export default Products;