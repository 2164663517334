import { useState, useEffect } from 'react';
import { BrowserView } from 'react-device-detect';
import { Anchor } from 'antd';

import request from './request';

const { Link } = Anchor;

const Categories = props => {
    const { locale } = props;
    const [categories, setCategories] = useState([]);
    //const [targetOffset, setTargetOffset] = useState(undefined);
    const rlocale=locale==="ar"?"en":"ar";
    
    useEffect(() => {
        //setTargetOffset(window.innerHeight / 2);
        request.get('/menu?categories=true').then(response => {
            setCategories(response.data);
        }).catch(e => {});
    }, []);

    return (
        <BrowserView>
            <Anchor offsetTop={-10} className='huemix-categories'>
                {categories.map(item => <Link key={item.name_en} href={`#${item.name_en}`} title={item[`name_${locale}`] || item[`name_${rlocale}`]} />)}
            </Anchor>
        </BrowserView>
    )
}

export default Categories;