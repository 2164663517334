import { useEffect, useState } from 'react';
import logo from './logo.png';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { BackTop, Layout, ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import arEG from 'antd/lib/locale/ar_EG';
import Products from './Products';
import Cart from './Cart';

import en from './locales/en.json';
import './App.css';
import request from './request';

const style = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#D4AF37',
  color: '#fff',
  textAlign: 'center',
  fontSize: 14,
}


const BACKEND = 'https://menuapp.rhuma.net/';

const { Header, Content, Footer } = Layout;

function App() {
  const [lang, setLang] = useState('ar');
  const [order, makeOrder] = useState({});
  const [table, setTable] = useState(null);

  const locale = lang==="ar"?"ar-LY":"en-US";

  const toggleLang = () => {
    if(lang==="ar") setLang("en");
    else setLang("ar")
  }

  useEffect(() => {
    var url = new URL(window.location.href);
    var c = url.searchParams.get("t");
    if(c) {
      request.get('/menu', { params: { validatetable: c } }).then(() => {
        setTable(c);
      }).catch(() => {});
    }
  }, []);

  return (
    <IntlProvider messages={lang==="ar"?[]:en} locale={locale} defaultLocale="ar-LY">
      <ConfigProvider locale={lang==="ar"?arEG:enUS} direction={lang==="ar"?"rtl":"ltr"}>
        <Layout className="layout">
          <Header>
            <div className="logo"><img src={logo} alt="Menu App" /></div>
            <div onClick={toggleLang} className="ChangeLang">{lang==="ar"?"EN":"AR"}</div>
          </Header>
          <BackTop>
            <div style={style}><FormattedMessage id="top" defaultMessage="للأعلى" /></div>
          </BackTop>
          <Content style={{ padding: '10px 50px' }}>
            <Cart table={table} prefix={BACKEND} order={order} locale={lang} />
            <Products table={table} makeOrder={makeOrder} prefix={BACKEND} locale={lang} />
          </Content>
          <Footer style={{ textAlign: 'center' }}>Ahmed Rhuma - Menu App ©2021</Footer>
        </Layout>
      </ConfigProvider>
    </IntlProvider>
    
  );
}

export default App;
