import { Typography, Badge, Divider, message, Button, Row, Col, Image, Spin } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ShoppingCartOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const SingleView = props => {
    const [visible, setVisible] = useState(false);
    const [extras, setExtras] = useState([]);
    const { makeOrder, table, rlocale, locale, intl, item, prefix } = props;

    const { row } = item;

    const selectExtras = ext => {
        let newx = [...extras];
        const ix=newx.findIndex(i => String(i.name_ar)===ext.name_ar);
        if(ix===-1) newx.push(ext)
        else newx=extras.filter(i => String(i.name_ar)!==String(ext.name_ar));
        setExtras(newx);
    }

    if(!item.visible || Object.keys(row).length===0) return <Spin />;

    const placeOrder = () => {
        makeOrder({...row, extras });
    }

    return(
        <Row gutter={16}>
            <Col xs={24} md={24} lg={10} xl={10} xxxl={10}>
                <Image
                    preview={{ visible: false }}
                    width='100%'
                    src={row.images.length>0?prefix+(row.images[0]):prefix+'default.jpg'}
                    onClick={() => setVisible(true)}
                />
                <div style={{ display: 'none' }}>
                    {row.images.length>0?<Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                        {row.images.map(i => <Image key={i} src={prefix+i} />)}
                    </Image.PreviewGroup>:null}
                </div>
                <Title style={{ textAlign: 'center', color: '#a08f25f5'}} level={5}>{intl.formatMessage({ id: 'price', defaultMessage: 'السِـعر' })}: {intl.formatNumber(row.price, { style: 'currency', currency: 'SAR' })}</Title>
                {table? <Button onClick={() => {
                    placeOrder();
                    message.success(intl.formatMessage({
                        id: 'added-to-cart',
                        defaultMessage: 'تم إضـافة {name} للسلة بنجاح.'
                    }, {name: (row[`name_${locale}`]) || (row[`name_${rlocale}`])}));
                }} block type="primary" icon={<ShoppingCartOutlined />}><FormattedMessage id="add-to-cart" defaultMessage="إضـافة للسـلة" /></Button> : null}
            </Col>
            <Col className='huemix-col' xs={24} md={24} lg={14} xl={14} xxxl={14}>
                <Title level={3}>{row[`name_${locale}`]}</Title>
                <Text>{row[`description_${locale}`]}</Text>
                {row.extras.length>0?<Row gutter={16}>
                    <Divider>{intl.formatMessage({ id: 'adds', defaultMessage: 'الإضـافات' })}</Divider>
                    {row.extras.map(itm => 
                    <Col key={itm.name} className="addons-col" span={24}>
                        <Badge.Ribbon className="huemix-robin color" color="purple" text={intl.formatNumber(itm.price, { style: 'currency', currency: 'SAR'})}>
                            <Button className={extras.findIndex(i => String(i.name_ar)===String(itm.name_ar))>=0?'extraactive':'not-active'} onClick={selectExtras.bind(null, itm)} block type="dashed">{itm[`name_${locale}`] || itm[`name_${rlocale}`]}</Button>
                        </Badge.Ribbon>
                    </Col>
                    )}
                </Row>:null}
            </Col>
        </Row>
    )
}

export default SingleView;