import { useState, useEffect } from 'react';
import { Input, InputNumber, Alert, Result, notification, Button, Typography, Space, Badge, Card, Table, message } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { CoffeeOutlined, DeleteOutlined } from '@ant-design/icons';

import request from './request';

const { Text } = Typography;

const Cart = props => {
    const { table, order, locale } = props;
    const intl = useIntl();
    const [notes, setNotes] = useState('');
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if(Object.keys(order).length>0){
            const neworders = [...orders];
            const ix = neworders.findIndex(i => String(i.id)===String(order.id));
            if(ix===-1) neworders.push(order);
            else {
                neworders[ix].qnt = (neworders[ix].qnt||1)+1;
            }
            setOrders(neworders);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(() => {
        if(success){
            setTimeout(() => setSuccess(false), 5000);
        }
    }, [success]);

    if(!table) return null;

    const askForWaitress = () => {
        request.post('/menu', { t:table, waitress: true }).then(() => {
            notification.success({
                message: intl.formatMessage({
                    id: 'waitress-on-the-way',
                    defaultMessage: 'الجَـرسُـون فِـي الطَـريق'
                }),
                description: intl.formatMessage({
                    id: 'waitress-on-the-way-desc',
                    defaultMessage: 'تم إسـتلام طَـلبكم وسيأتـي شخص مَـا لخدمتكم الآن، الرجاء الإنتظار قليلا.'
                })
              });
        }).catch((e) => {
            message.error(intl.formatMessage({
                id: 'error-request-waitress',
                defaultMessage: 'عُـذرا، حدث خـطأ أثـناء طلب الجَـرسون، الرَجـاء المُـحاولة مرة أخرى لاحقا.'
            }), 5);
        });
    }

    const sendOrder = () => {
        setLoading(true);
        request.post('/menu', { t:table, orders, notes }).then(res => {
            setLoading(false);
            setOrders([]);
            setNotes('');
            setSuccess(true);
        }).catch(e => {
            setLoading(false);
            message.error(intl.formatMessage({
                id: 'error-request',
                defaultMessage: 'حَـدث خطــأ أثـناء إرسـال الطَـلب، الرجـاء طَـلب الجرسـون.'
            }), 5);
        })
    }

    const Header = <div style={{ textAlign: 'center', marginBottom: '10px' }}><Space>
        <FormattedMessage id="need-help" defaultMessage="هـل تحتاج إلـى مُـساعدة؟" />
        <Button onClick={askForWaitress} type="primary" icon={<CoffeeOutlined />}><FormattedMessage id="call-waitress" defaultMessage="أطــلب حُـضور الجـرسُـون" /></Button>
    </Space></div>;

    const removeItem = item => {
        const neworders = orders.filter(i => String(i.id)!==String(item.id));
        setOrders(neworders);
    }

    const ChangeQnt = (row, e) => {
        const neworders = [...orders];
        const ix = neworders.findIndex(i => String(i.id)===String(row.id));
        if(ix>=0){
            neworders[ix].qnt=e;
            setOrders(neworders);
        }
    }

    const updateNotes = e => {
        setNotes(e.target.value);
    }

    const columns = [
        {
            title: intl.formatMessage({
                id: 'product',
                defaultMessage: 'الصِـنف'
            }),
            dataIndex: `name_${locale}`,
            expand: true,
            render: (item, row) => item + ' ' + ((row.extras||[]).length>0?' / ': '') + (row.extras||[]).slice(0).map(i => i[`name_${locale}`]).join(", ")
        },
        {
            title: intl.formatMessage({
                id: 'qnt',
                defaultMessage: 'الكَـمية'
            }),
            dataIndex: 'qnt',
            render: (item, row) => <InputNumber step={1} style={{width: '100%'}} min={1} value={item || 1} onChange={ChangeQnt.bind(null, row)} />
        },
        {
            title: intl.formatMessage({
                id: 'remove',
                defaultMessage: 'حذف'
            }),
            key: 'remove',
            render: item => <Button onClick={removeItem.bind(null, item)} type="primary" danger icon={<DeleteOutlined />} />
        }
    ];

    return (
        <Badge.Ribbon text={<FormattedMessage id="your-cart" defaultMessage="قــائِـمة طَـلباتك" />} color="purple">
            <Card title={false} size="small">
                <FormattedMessage id="cart-message" defaultMessage="يُـمكنك الطَـلب من قَـائِـمتنا مُـباشرة مِـن هُـنا" />
                <br />
                {Header}
                {success? <Result
                    status="success"
                    title={intl.formatMessage({
                        id: 'order-done',
                        defaultMessage: 'شُـكرا جزيلا، لقد وصل طلبك'
                    })}
                    subTitle={intl.formatMessage({
                        id: 'order-sent',
                        defaultMessage: 'لقد تم إرســـال طلبك بنجاح، سنقوم بتجهـيزه وإحضــاره لك.'
                    })}
                  /> : 
                orders.length === 0 ? <Alert icon={<CoffeeOutlined />} message={intl.formatMessage({ id: 'your-orders-here', defaultMessage: 'طَـلباتك سـتظهر هُـنا' })} type="warning" showIcon /> : 
                    <Table tableLayout="fixed" expandable={{
                        expandedRowRender: record => {
                            const itemtotal = (parseFloat(record.price)+((record.extras||[]).reduce((a, b) => (+parseFloat(a) || 0)+(+parseFloat(b.price) ||0), 0)));
                            const thingstotal = itemtotal*(parseInt(record.qnt || 1));
                            return (
                                <div className='row-data-huemix'>
                                    <Text>{intl.formatMessage({ id: 'item-price', defaultMessage: 'السعر للقطعة: ' })}</Text> 
                                    <Text type="secondary">{intl.formatNumber(itemtotal, { style: 'currency', currency: 'SAR' })}</Text>
                                    <Text> / </Text>
                                    <Text>{intl.formatMessage({ id: 'items-price', defaultMessage: 'السعر حسب الكمية: ' })}</Text> 
                                    <Text type="secondary">{intl.formatNumber(thingstotal, { style: 'currency', currency: 'SAR' })}</Text>
                                </div>
                            )
                        },
                        //rowExpandable: record => true,
                        defaultExpandAllRows: true,
                        expandIconColumnIndex: -1
                      }} loading={loading} pagination={false} summary={pageData => {
                    let totalprice = 0;

                    for(let i=0; i<(pageData || []).length; i++){
                        const { extras, price, qnt } = pageData[i];

                        totalprice += (+parseFloat(price) || 0)*(+parseInt(qnt)||1);
                        if(extras && extras.length>0){
                            for(let j=0; j<extras.length; j++) {
                                totalprice += (+parseFloat(extras[j].price)||0)*(+parseInt(qnt)||1);
                            }
                        }
                    }
        
                    return (
                        <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>{intl.formatMessage({ id: 'notes', defaultMessage: 'ملاحظات' })}</Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={2}>
                                <Input value={notes} placeholder={intl.formatMessage({ id: 'write-notes', defaultMessage: 'اذا كانت لديك أي ملاحظات اكتبها هنا....' })} onChange={updateNotes} />
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>{intl.formatMessage({ id: 'total', defaultMessage: 'الإجــمالي' })}</Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Text className="huemix-total" type="secondary" strong>{intl.formatNumber(totalprice, { style: 'currency', currency: 'SAR' })}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                                <Button disabled={orders.length===0} onClick={sendOrder} block type="primary" icon={<CoffeeOutlined />}><FormattedMessage id="order" defaultMessage="أطلب" /></Button>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        </>
                    );
                    }} columns={columns} dataSource={orders} size="small" /> }
            </Card>
        </Badge.Ribbon>
    )
}

export default Cart;